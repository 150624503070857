// Load Styles
import '../scss/main.scss';
/* Use Boilerplate Script */
/*
// Load Bootstrap init
import {initBootstrap} from "./bootstrap.js";

// Loading bootstrap with optional features
initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});
*/
/* Or use node_modules and import only needed scripts */
import { Util, ScrollSpy, Carousel } from 'bootstrap';

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faUser,faAngleRight,faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

library.add(faUser,faAngleRight,faAngleLeft,faEnvelope);
dom.watch();


/* Or import everything boostrap*/
//import * from "bootstrap";

// Your app code
//console.log(`Hello ${process.env.HELLO}`);

import Player from '@vimeo/player';

var vimeo = document.querySelector('.vimeo-overlay');
const trailer = new Player('vimeo-trailer', {
    id: 807305176,
    width: 640,
    responsive: true,
    byline: false
});
vimeo.addEventListener('click', function (event) {
  vimeo.classList.remove("d-flex");
  // Don't follow the link
  event.preventDefault();
  trailer.play()
}, false);
var body = document.querySelector('body');
var nav = document.querySelector('#main-nav');
var navToggle = document.getElementById('navbarToggler');
window.addEventListener('scroll', function() {
  let bounding = body.getBoundingClientRect()
      if (bounding.top == 0)
        body.classList.remove('scrolled')
      else
        body.classList.add('scrolled')

});
nav.addEventListener("click", function(e) { // e = event object
  if (e.target && e.target.matches(".nav-link")) {
    nav.classList.remove('toggled');
    navToggle.classList.remove('show')
    nav.querySelector('.navbar-toggler').classList.add('collapsed')
  }
});
navToggle.addEventListener('show.bs.collapse', function(){
  nav.classList.add('toggled')
})
navToggle.addEventListener('hide.bs.collapse', function(){
  nav.classList.remove('toggled')
})